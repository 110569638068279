import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GetProviderstocks } from "../../../services/ProviderServices";
import { getStrategy } from "../../../services/StrategyService";
import { getSearchStockDetails } from "../../../services/HomeService";
import {
  BsFillArrowUpRightCircleFill,
  BsArrowDownRightCircleFill,
} from "react-icons/bs";
import { Formik, Field, Form, useFormikContext } from "formik";
import { toast } from "react-toastify";
import { AddOpenTradeAPI } from "../../../services/TradeService";
import moment from "moment/moment";
import CountryCurrencyList from "../../commonComponent/CountryCurrencyList";
import * as Yup from "yup";
import {
  getExchangeRateAPI,
  getRapidExchangeRateAPI,
  getBinanceExchangeRateAPI,
} from "../../../services/commonServices";
import { IoReload } from "react-icons/io5";

let validationSchemaStopLoss = Yup.object().shape({
  PortfolioID: Yup.string().required("Portfolio is required"),
  ProviderID: Yup.string().required("Provider is required"),
  Strategy: Yup.string().required("Trading System is required"),
  StockCode: Yup.string().required("Crypto type is required"),
  TransFee: Yup.string().required("At Price is required"),
  ValueInShares: Yup.string().required("Value In Crypto is required"),
  quantity: Yup.string().required("Quantity is required"),
  StopLossPrice: Yup.string().required("Stop Price is required"),
});

let validationSchema = Yup.object().shape({
  PortfolioID: Yup.string().required("Portfolio is required"),
  ProviderID: Yup.string().required("Provider is required"),
  Strategy: Yup.string().required("Trading System is required"),
  StockCode: Yup.string().required("Crypto type  is required"),
  TransFee: Yup.string().required("At Price is required"),
  ValueInShares: Yup.string().required("Value In Crypto is required"),
  quantity: Yup.string().required("Quantity is required"),
});

const OpenTrade = ({
  countryList,
  setOpenTradeRun,
  runAgainDashBoard,
  setRunAgainDashBoard,
  providerList,
  userType,
  portfololioList,
  openTradeRun,
  calculatedData,
  setCalculatedData,
}) => {
  const [checkStop, setCheckStop] = useState("0");
  const [tradeInput, setTradeInput] = useState({
    type: "TRADES",
    tradeId: 0,
    Strategy: "",
    AtRiskDollar: 0,
    AtRiskPercent: 0,
    FromTransPriceDollar: 0,
    FromTransPricePercent: 0,
    GSLOTradingFee: 0,
    GSLOCost: 0,
    GSLOPrice: 0,
    StopLossPrice: "",
    Comments: "",
    ValueInShares: "",
    ValueInCFD: 0,
    TransCost: 0,
    TransFee: "",
    bid_size: 0,
    bid_price: 0,
    ask_size: "",
    ask_price: "",
    OpenDate: new Date(),
    Short: "buy",
    Margin: "",
    Share_Sector: "Stock",
    StockName: "",
    StockCode: "",
    UserId: "",
    ProviderID: "",
    PortfolioID: "",
    quantity: "",
    brockerId: "",
    country: "",
  });

  const {
    TransFee,
    type,
    Short,
    StockName,
    PortfolioID,
    ProviderID,
    ValueInShares,
    quantity,
    StopLossPrice,
    Share_Sector,
    brockerId,
    Strategy,
    StockCode,
  } = tradeInput;
  const [loading, setLoading] = useState(false);
  const [checkInput, setCheckInput] = useState(false);
  const [cryptoSelectValue, setCryptoSelectValue] = useState();
  const [stockLists, setStockLists] = useState([]);
  const [strategyList, setStrategyList] = useState([]);
  const [cryptoDetails, setCryptodetails] = useState([]);
  const [checkAskPrLo, setCheckAskPrLo] = useState("same");
  const [checkBidPrLo, setCheckBidPrLo] = useState("same");
  const [checkPricePrLo, setCheckPricePrLo] = useState("same");
  const [currencyCodes, setCurrencyCodes] = useState({
    stockCountryCode: "",
    portfolioCountryCode: "",
    portfolioCurrency: "",
    stockCountrySymbol: "",
  });
  const {
    stockCountryCode,
    portfolioCountryCode,
    portfolioCurrency,
    stockCountrySymbol,
  } = currencyCodes;
  const [showCodeList, setShowCodeList] = useState(false);
  const [stockpageData, setStockPageData] = useState({
    id: "",
    PageNumber: 1,
    PageSize: 200,
    search: "",
  });
  const [brokerList, setBrokerList] = useState(portfololioList);
  const [exchangeRate, setExchangerate] = useState(0);
  const [stockcurrencyconvert, setStockCurrencyConvert] = useState("");
  // const[ stocksymbolconvert,setStockSymbolConvert] =useState("")
  // useEffect(() => {
  //     setTradeInput({ ...tradeInput, UserId: userType.userId })
  // }, [userType])

  useEffect(() => {
    let filteredProviderId;

    if (
      calculatedData !== undefined &&
      calculatedData !== "" &&
      calculatedData.stockCode.length > 0
    ) {
      // debugger
      // console.log("calculatedData", calculatedData)
      let filteredProvider = providerList.filter(
        (val) => val?.providerName == calculatedData?.providerName
      );
      let selectedStockCountry = countryList?.filter(
        (val) => val.key.toUpperCase() == calculatedData?.country.toUpperCase()
      );
      let calaus = calculatedData?.stockCode?.split("_");
      let newcalaus = [];
      if (
        calaus[3] === "USDC" ||
        calaus[3] === "USDT" ||
        calaus[3] === "USDB"
      ) {
        newcalaus = "USD";
      } else {
        newcalaus = calaus[calaus.length - 1];
      }

      let symbol = CountryCurrencyList.filter((val) => val.code == newcalaus);

      setStockCurrencyConvert(newcalaus);

      let portfolioid = calculatedData?.portFolioId;
      let portfolioSelected = portfololioList.filter(
        (val) => portfolioid == val.portfolioId
      );
      // let pValue = e.target.value

      let coinCountrySymbol = CountryCurrencyList?.filter(
        (val) => val?.code == portfolioSelected[0]?.currency
      );

      setCurrencyCodes({
        ...currencyCodes,
        stockCountrySymbol: symbol[0]?.symbol_native || "",
        portfolioCountryCode: coinCountrySymbol[0]?.code || "",
        stockCountryCode: newcalaus || "",
        portfolioCurrency: coinCountrySymbol[0]?.symbol_native || "",
      });
      // setCurrencyCodes({ ...currencyCodes, portfolioCountryCode: coinCountrySymbol[0]?.code||'',  })
      //  console.log(portfolioCountryCode ,portfolioCurrency,stockCountrySymbol,stockCountryCode, "portfolioCountryCode,portfolioCurrency,stockCountrySymbol,stockCountryCode")
      // setCurrencyCodes({ ...currencyCodes, portfolioCurrency: coinCountrySymbol[0].symbol_native })
      let aus = calculatedData?.stockCode?.split("_");

      setCryptodetails([
        ...cryptoDetails,
        {
          s: s + 1,
          askPrice: calculatedData.ask_price,
          bidPrice: calculatedData.bid_price,
          price: calculatedData.last_trade?.price || 0,
          tradeType: calculatedData.last_trade?.taker_side || "NA",
        },
      ]);

      // setInitialState({ ...initialState, transactionPrice: res.data[0]?.close, stockName: value.stockName, margin: value.margin, stockCode: value.stockCode })

      if (
        calculatedData?.portFolioId != "" &&
        calculatedData.portFolioId != undefined
      ) {
        // debugger
        let portFolioFilter = brokerList.filter(
          (value) => value.portfolioId == calculatedData.portFolioId
        );
        // debugger
        let to = portFolioFilter[0]?.currency;
        try {
          // debugger;
          getRapidExchangeRateAPI(
            selectedStockCountry[0].value,
            portFolioFilter[0]?.currency
          ).then((respons) => {
            // getExchangeRateAPI(selectedStockCountry[0].value, portFolioFilter[0]?.currency).then((respons) => {
            if (respons.data?.status === undefined) {
              const exPrice = Number(
                respons.data.rates?.[to]
                //   respons.data["Realtime Currency Exchange Rate"][
                //     "5. Exchange Rate"
                //   ]
              );
              // debugger
              setCryptoSelectValue({
                ask_price: calculatedData?.ask_price,
                bid_price: calculatedData?.bid_price,
              });

              setExchangerate(exPrice);
              setTradeInput({
                ...tradeInput,
                bid_size: calculatedData.bid_size,
                bid_price: calculatedData?.bid_price,
                ask_size: calculatedData.ask_size,
                ask_price: calculatedData?.ask_price,
                TransFee: calculatedData?.entry,
                tradeId: calculatedData.tradeId,
                type: calculatedData?.type || "TRADES",
                StockCode: calculatedData.stockCode,
                Margin: calculatedData.margin,
                PortfolioID: calculatedData?.portFolioId,
                userId: userType?.userId,
                Strategy: calculatedData?.tradingSystemId,
                country: calculatedData?.country,
                ProviderID: filteredProvider[0]?.providerId,
                providerName: calculatedData?.providerName,
                StockName: aus[2] + "/" + aus[3],
                Share_Sector: "Stock",
                Short: calculatedData?.short,
                openDate: moment(new Date()).format("DD/MM/YYYY"),
                quantity:
                  calculatedData.quantity > 0
                    ? calculatedData.quantity.toFixed(3)
                    : "",
                ValueInShares:
                  calculatedData?.entry * exPrice * calculatedData.quantity,
                transactionPrice: calculatedData?.entry,
                StopLossPrice: calculatedData?.stopLoss,
                // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
              });
            } else if (respons.data.status === 400) {
              getBinanceExchangeRateAPI(
                selectedStockCountry[0].value,
                portFolioFilter[0]?.currency
              ).then((respn) => {
                  const exPrice = Number(
                    respn.data.price
                    //   respons.data["Realtime Currency Exchange Rate"][
                    //     "5. Exchange Rate"
                    //   ]
                  );
                  // debugger
                  setCryptoSelectValue({
                    ask_price: calculatedData?.ask_price,
                    bid_price: calculatedData?.bid_price,
                  });

                  setExchangerate(exPrice);
                  setTradeInput({
                    ...tradeInput,
                    bid_size: calculatedData.bid_size,
                    bid_price: calculatedData?.bid_price,
                    ask_size: calculatedData.ask_size,
                    ask_price: calculatedData?.ask_price,
                    TransFee: calculatedData?.entry,
                    tradeId: calculatedData.tradeId,
                    type: calculatedData?.type || "TRADES",
                    StockCode: calculatedData.stockCode,
                    Margin: calculatedData.margin,
                    PortfolioID: calculatedData?.portFolioId,
                    userId: userType?.userId,
                    Strategy: calculatedData?.tradingSystemId,
                    country: calculatedData?.country,
                    ProviderID: filteredProvider[0]?.providerId,
                    providerName: calculatedData?.providerName,
                    StockName: aus[2] + "/" + aus[3],
                    Share_Sector: "Stock",
                    Short: calculatedData?.short,
                    openDate: moment(new Date()).format("DD/MM/YYYY"),
                    quantity:
                      calculatedData.quantity > 0
                        ? calculatedData.quantity.toFixed(3)
                        : "",
                    ValueInShares:
                      calculatedData?.entry * exPrice * calculatedData.quantity,
                    transactionPrice: calculatedData?.entry,
                    StopLossPrice: calculatedData?.stopLoss,
                    // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
                  });
              });
            }
          });
        } catch (error) {}
      } else {
        // debugger
        setTradeInput({
          ...tradeInput,
          bid_size: calculatedData.bid_size,
          bid_price: calculatedData?.bid_price,
          ask_size: calculatedData.ask_size,
          ask_price: calculatedData?.ask_price,
          TransFee:
            calculatedData?.short == "buy"
              ? calculatedData?.ask_price
              : calculatedData?.bid_price,
          tradeId: calculatedData.tradeId,
          type: calculatedData?.type || "TRADES",
          StockCode: calculatedData.stockCode,
          Margin: calculatedData.margin,
          PortfolioID: calculatedData?.portFolioId,
          userId: userType?.userId,
          Strategy: calculatedData?.tradingSystemId,
          country: calculatedData?.country,
          ProviderID: calculatedData?.providerID,
          providerName: calculatedData?.providerName,
          StockName: aus[2] + "/" + aus[3],
          Share_Sector: "Stock",
          Short: calculatedData?.short,
          openDate: moment(new Date()).format("DD/MM/YYYY"),
          quantity:
            calculatedData.quantity > 0
              ? calculatedData.quantity.toFixed(3)
              : "",
          ValueInShares: "",
          transactionPrice:
            calculatedData?.short == "buy"
              ? calculatedData?.ask_price
              : calculatedData?.bid_price,
          StopLossPrice: calculatedData?.stopLoss,
          // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
        });
      }
      if (calculatedData.stopLoss > 0) {
        // debugger
        setCheckStop("1");
      }
    } else {
      setBrokerList(portfololioList);
    }
  }, [calculatedData, portfololioList]);

  const getProviderStockList = (nm) => {
    // debugger
    nm.id = ProviderID;
    if (nm?.id !== undefined && nm?.id > 0) {
      try {
        GetProviderstocks(nm).then((res) => {
          if (res?.status === 200) {
            // console.log("res?.data?.data", res?.data?.data)
            let data = res.data.data;
            setStockLists(data);
          }
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    // debugger
    // console.log('use')
    //   console.log("use2", ProviderID);
    getProviderStockList(stockpageData);
  }, [ProviderID]);
  const handleGetSequrityName = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      scrollTop + clientHeight <= scrollHeight + 100
    ) {
      let nm = stockpageData;
      nm.PageSize = nm.PageSize + 100;
      setStockPageData({ ...nm });
      if (nm.id !== undefined && stockLists.length >= nm.PageSize) {
        getProviderStockList(nm);
      }
    }
    // setInitialState({ ...initialState, stockName: e.target.value })
    //
  };

  const handleStockData = async (value) => {
    // let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == value.country.toUpperCase())
    // let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)

    // console.log(selectedStockCountry, symbol, "selectedStockCountry ,symbol")
    // setCurrencyCodes({ ...currencyCodes, stockCountrySymbol: symbol[0]?.symbol_native, stockCountryCode: selectedStockCountry[0]?.value })

    setShowCodeList(false);
    let aus = value.stockCode.split("_");

    let newaus = [];
    if (aus[3] === "USDC" || aus[3] === "USDT" || aus[3] === "USDB") {
      newaus = "USD";
    } else {
      newaus = aus[3];
    }
    let symbol = CountryCurrencyList.filter((val) => val.code == newaus);

    setStockCurrencyConvert(newaus);

    setCurrencyCodes({
      ...currencyCodes,
      stockCountrySymbol: symbol[0]?.symbol_native,
      stockCountryCode: aus,
    });
    // let portfolioCountry = countryList.filter((val) => val.key.toUpperCase() == value.country.toUpperCase())
    // let portfoliosymbol =  CountryCurrencyList.filter((val) => val.code == currencyCodes?.portfolioCountryCode)
    try {
      // debugger;

      const respons = await getRapidExchangeRateAPI(aus[2], newaus);
      // getExchangeRateAPI(aus[2], newaus).then((respons) => {
      if (respons?.data?.status === undefined) {
        try {
          const resp = await getRapidExchangeRateAPI(
            newaus,
            currencyCodes?.portfolioCountryCode
          );
          //   console.log(
          //     " currencyCodes?.portfolioCountryCode",
          //     currencyCodes?.portfolioCountryCode,
          //     respons.data.rates,
          //       respons.data.rates?.[newaus],
          //     resp
          //   );
          // getExchangeRateAPI(newaus, currencyCodes?.portfolioCountryCode).then((resp) => {
          if (resp.data) {
            // console.log(
            //   "response?.data",
            //   resp.data,
            //   currencyCodes?.portfolioCountryCode,
            //   respons.data.rates
            // );

            // comment: exprice is the exchange rate of Crypto Currency & Porfolio Currency
            // bid / ask price is exchange rate of Select Crypto Type

            const exPrice = Number(
              resp.data.rates?.[currencyCodes?.portfolioCountryCode] || 0 //   resp.data["Realtime Currency Exchange Rate"][
              //     "5. Exchange Rate"
              //   ]
            );
            const bidPrice = Number(
              respons.data.rates?.[newaus] || 0
              //   respons.data["Realtime Currency Exchange Rate"][
              //     "8. Bid Price"
              //   ]
            );
            const askPrice = Number(
              respons.data.rates?.[newaus] || 0
              //   respons.data["Realtime Currency Exchange Rate"][
              //     "9. Ask Price"
              //   ]
            );
            setExchangerate(exPrice);

            let data = {
              askPrice: askPrice,
              bidPrice: bidPrice,
            };
            setCryptoSelectValue(data);
            if (Short == "buy") {
              setTradeInput({
                ...tradeInput,
                bid_size: 0,
                bid_price: bidPrice.toFixed(3),
                ask_size: 0,
                ask_price: askPrice.toFixed(3),
                TransFee: askPrice,
                StockCode: value.stockCode,
                StockName: aus[2] + "/" + aus[3],
                Margin: value.margin,
                country: value.country,
                ValueInShares: "",
                quantity: "",
                StopLossPrice: checkStop == "1" ? "" : 0,
              });
            } else {
              setTradeInput({
                ...tradeInput,
                bid_size: 0,
                bid_price: bidPrice.toFixed(3),
                ask_size: 0,
                ask_price: askPrice.toFixed(3),
                TransFee: bidPrice,
                StockCode: value.stockCode,
                StockName: aus[2] + "/" + aus[3],
                Margin: value.margin,
                country: value.country,
                ValueInShares: "",
                quantity: "",
                StopLossPrice: checkStop == "1" ? "" : 0,
              });
            }
            setCryptodetails([
              ...cryptoDetails,
              {
                s: s + 1,
                askPrice: askPrice,
                bidPrice: bidPrice,
                price: 0,
                tradeType: "NA",
              },
            ]);
          }
        } catch (error) {}
      } else if (respons?.data?.status === 400) {
          let newaus = [];
          if (aus[3] === "USD") {
            newaus = "USDT";
          } else {
            newaus = aus[3];
          }
        const respons = await getBinanceExchangeRateAPI(aus[2], newaus);
        try {
          const resp = await getRapidExchangeRateAPI(
            newaus,
            currencyCodes?.portfolioCountryCode
          );
          if (resp.data) {
            const exPrice = Number(
              resp.data.price?.[currencyCodes?.portfolioCountryCode] || 0
            );
            const bidPrice = Number(
              respons.data.price || 0
            );
            const askPrice = Number(
              respons.data.price || 0
            );
            setExchangerate(exPrice);

            let data = {
              askPrice: askPrice,
              bidPrice: bidPrice,
            };
            setCryptoSelectValue(data);
            if (Short == "buy") {
              setTradeInput({
                ...tradeInput,
                bid_size: 0,
                bid_price: bidPrice.toFixed(3),
                ask_size: 0,
                ask_price: askPrice.toFixed(3),
                TransFee: askPrice,
                StockCode: value.stockCode,
                StockName: aus[2] + "/" + aus[3],
                Margin: value.margin,
                country: value.country,
                ValueInShares: "",
                quantity: "",
                StopLossPrice: checkStop == "1" ? "" : 0,
              });
            } else {
              setTradeInput({
                ...tradeInput,
                bid_size: 0,
                bid_price: bidPrice.toFixed(3),
                ask_size: 0,
                ask_price: askPrice.toFixed(3),
                TransFee: bidPrice,
                StockCode: value.stockCode,
                StockName: aus[2] + "/" + aus[3],
                Margin: value.margin,
                country: value.country,
                ValueInShares: "",
                quantity: "",
                StopLossPrice: checkStop == "1" ? "" : 0,
              });
            }
            setCryptodetails([
              ...cryptoDetails,
              {
                s: s + 1,
                askPrice: askPrice,
                bidPrice: bidPrice,
                price: 0,
                tradeType: "NA",
              },
            ]);
          }
        } catch (error) {}

      } 

    } catch (error) {}

  };
  // get trading system list
  const getTradingSystemList = () => {
    if (userType?.userId !== undefined) {
      try {
        getStrategy(userType?.userId).then((res) => {
          if (res?.status === 200) {
            let optionsStrategy = [];
            let data = res.data;

            for (let i = 0; i < data.length; i++) {
              optionsStrategy.push({
                value: data[i].tradingSystemId,
                label: data[i].name,
              });
            }
            setStrategyList(data);
            // setRunAgainbAPI(false)
          }
        });
      } catch (error) {}
    }
  };
  useEffect(() => {
    getTradingSystemList();
  }, [userType]);

  // get Crypto Details
  let s = 0;
  const twoDecimal = (value) => {
    var roundedNumber = value;
    var decimalIndex = roundedNumber.indexOf(".");
    if (decimalIndex !== -1) {
      var decimalPlaces = roundedNumber.length - decimalIndex - 1;
      if (decimalPlaces > 5) {
        roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
      }
    }
    return roundedNumber;
  };

  const handleChengeTradeInput = async (e) => {
    // debugger
    if (e.target.name == "PortfolioID") {
      // debugger
      let portfolioSelected = portfololioList.filter(
        (val) => e.target.value == val.portfolioId
      );
      let pValue = e.target.value;
      setCurrencyCodes({
        ...currencyCodes,
        portfolioCountryCode: portfolioSelected[0]?.currency,
        portfolioCurrency: portfolioSelected[0]?.currencySymbol,
      });
      if (calculatedData !== undefined && calculatedData !== "") {
        let selectedStockCountry = countryList.filter(
          (val) => val.key.toUpperCase() == calculatedData.country.toUpperCase()
        );
        let stockCodeArr = StockCode.split("_");
        try {
          // debugger;
          const respons = await getRapidExchangeRateAPI(
            stockCodeArr[2],
            selectedStockCountry[0]?.value
          );
          // getExchangeRateAPI(stockCodeArr[2], selectedStockCountry[0]?.value).then((respons) => {
          if (respons?.data?.status === undefined) {
            try {
              // debugger;
              const res = await getRapidExchangeRateAPI(
                selectedStockCountry[0]?.value,
                portfolioSelected[0]?.currency
              );
              // getExchangeRateAPI(
              //   selectedStockCountry[0]?.value,
              //   portfolioSelected[0]?.currency
              // ).then((res) => {
              if (res.data) {
                const exPrice = Number(
                  res.data.rates?.[portfolioSelected[0]?.currency] || 0
                  // res.data["Realtime Currency Exchange Rate"][
                  //   "5. Exchange Rate"
                  // ]
                );
                const bidPrice = Number(
                  respons.data.rates?.[selectedStockCountry[0]?.value] || 0
                  // respons.data["Realtime Currency Exchange Rate"][
                  //   "8. Bid Price"
                  // ]
                );
                const askPrice = Number(
                  respons.data.rates?.[selectedStockCountry[0]?.value] || 0
                  // respons.data["Realtime Currency Exchange Rate"][
                  //   "9. Ask Price"
                  // ]
                );
                let data = {
                  askPrice: askPrice,
                  bidPrice: bidPrice,
                };
                setCryptoSelectValue(data);
                setExchangerate(exPrice);
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: pValue,
                  bid_price: calculatedData.bid_price,
                  ask_price: calculatedData.ask_price,
                  TransFee:
                    calculatedData.short == "buy"
                      ? calculatedData.ask_price
                      : calculatedData.bid_price,
                  ValueInShares:
                    calculatedData?.quantity ||
                    0 * (calculatedData?.currentPrice || 0) * exPrice,
                  transactionPrice: calculatedData?.currentPrice,
                  StopLossPrice: calculatedData.stopLoss,
                  // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
                });

                setCryptodetails([
                  ...cryptoDetails,
                  {
                    s: s + 1,
                    askPrice: Number(askPrice).toFixed(3),
                    bidPrice: Number(bidPrice).toFixed(3),
                    price: 0,
                    tradeType: "NA",
                  },
                ]);
              }
            } catch (error) {}
          } else if(respons?.data?.status === 400) {
            const respons = await getBinanceExchangeRateAPI(
              stockCodeArr[2],
              selectedStockCountry[0]?.value
            );
          }
        } catch (error) {}
      } else {
        // debugger;
        setTradeInput({
          ...tradeInput,
          [e.target.name]: e.target.value,
          ValueInShares: "",
          transactionPrice: 0,
          StopLossPrice: checkStop == "1" ? "" : 0,
          quantity: "",
          // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
        });
      }
    } else if (e.target.name == "ProviderID") {
      // debugger;
      if (PortfolioID == "") {
        // debugger
        toast.error("Select portfolio first.");
      } else {
        // debugger
        setCryptoSelectValue({});
        setStockPageData({
          ...stockpageData,
          search: "",
        });
        setTradeInput({
          ...tradeInput,
          [e.target.name]: e.target.value,
          bid_size: "",
          bid_price: "",
          ask_size: "",
          ask_price: "",
          TransFee: "",
          StockCode: "",
          StockName: "",
          Margin: "",
          country: "",
          ValueInShares: "",
          StopLossPrice: checkStop == "1" ? "" : 0,
          quantity: "",
        });

        setCryptodetails([
          ...cryptoDetails,
          { askPrice: 0, bidPrice: 0, price: 0, tradeType: "NA" },
        ]);
      }
    } else if (e.target.name == "StockName") {
      // debugger
      if (PortfolioID == "") {
        toast.error("Select portfolio first.");
      } else if (ProviderID == "") {
        toast.error("Select provider first.");
      } else {
        let nm = stockpageData;
        let n = e.target.value.replace("/", "_");
        nm.search = n;
        setTradeInput({ ...tradeInput, [e.target.name]: e.target.value });
        setShowCodeList(true);
        setStockPageData({
          ...nm,
        });
        if (nm.id !== undefined) {
          getProviderStockList(nm);
        }
      }
    } else if (e.target.name == "quantity") {
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
        if (PortfolioID == "") {
          toast.error("Select portfolio first.");
        } else if (ProviderID == "") {
          toast.error("Select provider first.");
        } else if (StockCode.length == 0 || StockCode == "") {
          toast.error("Select crypto type first.");
        } else if (TransFee <= 0 || TransFee == "") {
          toast.error("Enter at price first.");
        } else {
          let roundedNumber = twoDecimal(e.target.value.toString());
          let vs = TransFee * exchangeRate * roundedNumber;
          let n = e.target.value.split(".");
          let value = twoDecimal(e.target.value);
          if (n.length == 2) {
            if (n[0].length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: roundedNumber,
                ValueInShares: vs,
              });
            }
          } else if (n.length == 1 && e.target.value.length <= 10) {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: roundedNumber,
              ValueInShares: vs,
            });
          }
        }
      }
    } else if (e.target.name == "ValueInShares") {
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
        if (PortfolioID == "") {
          toast.error("Select portfolio first.");
        } else if (ProviderID == "") {
          toast.error("Select provider first.");
        } else if (StockCode.length == 0 || StockCode == "") {
          toast.error("Select crypto type first.");
        } else if (TransFee <= 0 || TransFee == "") {
          toast.error("Enter at price first.");
        } else {
          let roundedNumber = twoDecimal(e.target.value);

          let qty = roundedNumber / (TransFee * exchangeRate);
          let q = qty;
          let n = e.target.value.split(".");
          if (n.length == 2) {
            if (n[0].length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: roundedNumber,
                quantity: q,
              });
            }
          } else if (n.length == 1 && e.target.value.length <= 10) {
            setTradeInput({
              ...tradeInput,
              [e.target.name]: roundedNumber,
              quantity: q,
            });
          }
        }
      }
    } else if (e.target.name == "TransFee") {
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
        if (PortfolioID == "") {
          toast.error("Select portfolio first.");
        } else if (ProviderID == "") {
          toast.error("Select provider first.");
        } else if (StockCode.length == 0 || StockCode == "") {
          toast.error("Select crypto type first.");
        } else {
          let roundedTrans = twoDecimal(e.target.value);
          if (
            ValueInShares !== "" &&
            ValueInShares !== undefined &&
            ValueInShares !== 0
          ) {
            let qty = (ValueInShares / Number(roundedTrans)) * exchangeRate;
            let q = qty;
            let n = e.target.value.split(".");
            if (n.length == 2) {
              if (n[0].length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: Number(roundedTrans),
                  quantity: q,
                  StopLossPrice: checkStop == "1" ? "" : 0,
                });
              }
            } else if (n.length == 1 && e.target.value.length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: Number(roundedTrans),
                quantity: q,
                StopLossPrice: checkStop == "1" ? "" : 0,
              });
            }
          } else if (
            ValueInShares == "" &&
            ValueInShares == undefined &&
            ValueInShares == 0 &&
            quantity !== "" &&
            quantity !== undefined &&
            quantity !== 0
          ) {
            let vs = quantity * Number(roundedTrans);
            let n = e.target.value.split(".");
            if (n.length == 2) {
              if (n[0].length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: Number(roundedTrans),
                  ValueInShares: vs,
                });
              }
            } else if (n.length == 1 && e.target.value.length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: Number(roundedTrans),
                ValueInShares: vs,
              });
            }
          } else if (StockCode !== "") {
            let n = e.target.value.split(".");
            if (n.length == 2) {
              if (n[0].length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: Number(roundedTrans),
                });
              }
            } else if (n.length == 1 && e.target.value.length <= 10) {
              setTradeInput({
                ...tradeInput,
                [e.target.name]: Number(roundedTrans),
              });
            }
          }
        }
      } else if (e.target.value == "") {
        setTradeInput({ ...tradeInput, [e.target.name]: e.target.value });
      }
    } else if (e.target.name == "StopLossPrice") {
      if (Number(e.target.value) >= 0 || e.target.value == ".") {
        if (PortfolioID == "") {
          toast.error("Select portfolio first.");
        } else if (ProviderID == "") {
          toast.error("Select provider first.");
        } else if (StockCode.length == 0 || StockCode == "") {
          toast.error("Select crypto type first.");
        } else if (TransFee <= 0 || TransFee == "") {
          toast.error("Enter at price first.");
        } else {
          let roundedNumber = twoDecimal(e.target.value);
          if (Short == "buy") {
            if (TransFee > Number(roundedNumber)) {
              let n = e.target.value.split(".");
              if (n.length == 2) {
                if (n[0].length <= 10) {
                  setTradeInput({
                    ...tradeInput,
                    [e.target.name]: roundedNumber,
                  });
                }
              } else if (n.length == 1 && e.target.value.length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: roundedNumber,
                });
              }
            } else {
              toast.error(
                "Set lower stop price than at price while buy trading"
              );
            }
          } else {
            let n = e.target.value.split(".");
            if (n.length == 2) {
              if (n[0].length <= 10) {
                setTradeInput({
                  ...tradeInput,
                  [e.target.name]: roundedNumber,
                });
              }
            } else if (n.length == 1 && e.target.value.length <= 10) {
              setTradeInput({ ...tradeInput, [e.target.name]: roundedNumber });
            }
          }
        }
      }
    } else {
      setTradeInput({ ...tradeInput, [e.target.name]: e.target.value });
    }
  };

  const validationStoploss = () => {
    if (Short == "sell") {
      if (TransFee >= Number(StopLossPrice)) {
        toast.error("Set higher stop price than at price while sell trading");
      }
    }
  };
  const handleChangeTradeType = (val) => {
    // debugger
    if (val === "buy") {
      setTradeInput({
        ...tradeInput,
        TransFee: cryptoSelectValue?.askPrice || "",
        ValueInShares: "",
        quantity: "",
        StopLossPrice: "",
        Short: val,
      });
    } else if (val === "sell") {
      setTradeInput({
        ...tradeInput,
        TransFee: cryptoSelectValue?.bidPrice || "",
        ValueInShares: "",
        quantity: "",
        StopLossPrice: "",
        Short: val,
      });
    }
    setCheckInput(false);
  };

  const openTradeFun = () => {
    let stockCodeArr = tradeInput.StockCode.split("_");
    // let newstockCodeArr = []
    // if (stockCodeArr[3] == 'USDC' || stockCodeArr[3] == 'USDB' || stockCodeArr[3] == 'USDT') {
    //     newstockCodeArr = 'USD'
    // }
    // else {
    //     newstockCodeArr = stockCodeArr[3]
    // }
    let a = stockCodeArr - 1;
    let sCode;
    if (stockCodeArr[a] == "USDC") {
      sCode = tradeInput.StockCode.replace("USDC", "USD");
    } else if (stockCodeArr[a] == "USDB") {
      sCode = tradeInput.StockCode.replace("USDB", "USD");
    } else if (stockCodeArr[a] == "USDT") {
      sCode = tradeInput.StockCode.replace("USDT", "USD");
    } else {
      sCode = tradeInput.StockCode;
    }
    let apiData = {
      PortfolioID: Number(tradeInput.PortfolioID),
      userId: userType?.userId,
      Strategy: Number(tradeInput.Strategy),
      country: tradeInput.country,
      ProviderID: Number(tradeInput.ProviderID),
      StockCode: sCode,
      StockName: tradeInput.StockName,
      Share_Sector: tradeInput.Share_Sector,
      Margin: tradeInput.Margin.toString(),
      Short: tradeInput.Short,
      OpenDate: new Date(),
      tradeId: tradeInput.tradeId,
      type: tradeInput.type,
      quantity: tradeInput.quantity.toString(),
      TransFee: Number(tradeInput.TransFee),
      TransCost: Number(tradeInput.TransCost),
      ValueInCFD: Number(tradeInput.ValueInCFD),
      ValueInShares: tradeInput.ValueInShares.toString(),
      Comments: tradeInput.Comments,
      StopLossPrice: tradeInput.StopLossPrice.toString(),
      GSLOPrice: Number(tradeInput.GSLOPrice),
      GSLOCost: Number(tradeInput.GSLOCost),
      GSLOTradingFee: Number(tradeInput.GSLOTradingFee),
      FromTransPricePercent: Number(tradeInput.FromTransPricePercent),
      FromTransPriceDollar: Number(tradeInput.FromTransPriceDollar),
      AtRiskPercent: Number(tradeInput.AtRiskPercent),
      AtRiskDollar: Number(tradeInput.AtRiskDollar),
      bid_size: tradeInput.bid_size.toString(),
      bid_price: tradeInput.bid_price.toString(),
      ask_size: tradeInput.ask_size.toString(),
      ask_price: tradeInput.ask_price.toString(),
    };
    setLoading(true);
    try {
      AddOpenTradeAPI(apiData)
        .then((res) => {
          if (res.status === 200) {
            let optmClose = document.getElementById("openTradeModelCloseBtn");
            optmClose.click();
            setOpenTradeRun(true);
            setRunAgainDashBoard(runAgainDashBoard + 1);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const handletradeBuySell = () => {
    if (checkStop == "0") {
      if (
        TransFee !== "" &&
        ValueInShares !== "" &&
        quantity !== "" &&
        Share_Sector !== "" &&
        PortfolioID !== "" &&
        Strategy !== "" &&
        StockCode !== ""
      ) {
        setCheckInput(false);
        openTradeFun();
      } else {
        setCheckInput(true);
      }
    } else if (checkStop == "1") {
      if (
        TransFee !== "" &&
        ValueInShares !== "" &&
        quantity !== "" &&
        StopLossPrice !== "" &&
        Share_Sector !== "" &&
        PortfolioID !== "" &&
        Strategy !== "" &&
        StockCode !== ""
      ) {
        setCheckInput(false);
        openTradeFun();
      } else {
        setCheckInput(true);
      }
    } else if (checkStop == "2") {
      if (
        TransFee !== "" &&
        ValueInShares !== "" &&
        quantity !== "" &&
        Share_Sector !== "" &&
        PortfolioID !== "" &&
        Strategy !== "" &&
        StockCode !== ""
      ) {
        setCheckInput(false);
        openTradeFun();
      } else {
        setCheckInput(true);
      }
    }
  };
  const handleRemoveOpenData = () => {
    setCheckStop(0);
    setCryptodetails([
      ...cryptoDetails,
      { s: 0, askPrice: 0, bidPrice: 0, price: 0, tradeType: "NA" },
    ]);
    setCurrencyCodes({
      ...currencyCodes,
      stockCountryCode: "",
      portfolioCountryCode: "",
      portfolioCurrency: "",
    });
    setTradeInput({
      stockName: "",
      type: "TRADES",
      tradeId: 0,
      Strategy: "",
      AtRiskDollar: 0,
      AtRiskPercent: 0,
      FromTransPriceDollar: 0,
      country: "",
      FromTransPricePercent: 0,
      GSLOTradingFee: 0,
      GSLOCost: 0,
      GSLOPrice: 0,
      StopLossPrice: "",
      Comments: "",
      ValueInShares: "",
      ValueInCFD: 0,
      TransCost: 0,
      TransFee: "",
      bid_size: 0,
      bid_price: 0,
      ask_size: "",
      ask_price: "",
      OpenDate: new Date(),
      Short: "buy",
      Margin: "",
      Share_Sector: "Stock",
      StockName: "",
      StockCode: "",
      UserId: userType?.userId,
      ProviderID: "",
      PortfolioID: "",
      quantity: "",
      brockerId: "",
    });
    setStockPageData({
      ...stockpageData,
      search: "",
    });
    setCryptoSelectValue({});
  };
  const handleBlurOpenTrade = () => {};
  const handleChangeStopLoss = (e) => {
    if (e.target.value == "0") {
      setTradeInput({
        ...tradeInput,
        StopLossPrice: 0,
        // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
      });
    } else {
      setTradeInput({
        ...tradeInput,
        StopLossPrice: "",
        // brockerId: (calculatedData?.providerId || filteredProviderId[0]?.provider?.providerId) + '-' + calculatedData?.portFolioId
      });
    }
    setCheckStop(e.target.value);
  };
  const RefreshExchangeRate = () => {
    // debugger;
    setLoading(true);
    // getExchangeRateAPI(stockCountryCode, portfolioCountryCode)
    getRapidExchangeRateAPI(stockcurrencyconvert, portfolioCountryCode)
      .then((response) => {
        if (response?.status === 200) {
          const exchange = response.data.rates?.[portfolioCountryCode] || 1;
          // response?.data["Realtime Currency Exchange Rate"]?.[
          //   "5. Exchange Rate"
          // ] || 1;
          setExchangerate(exchange);
        }
      })
      .catch((error) => {
        // Handle error if needed
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRefresh = () => {
    RefreshExchangeRate();
  };
  return (
    <>
      <div
        class="modal fade backdrop-1"
        id="openTrade"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="exampleModalLgLabel"
        aria-hidden="true"
        aria-modal="true"
        role="dialog"
        style={{ display: "none;" }}
      >
        <div class="modal-dialog modal-lg mt-5 openTrade_model_size">
          <div class="modal-content cal-model">
            <div class="modal-header">
              <h5 class="modal-title h4 white_text" id="openTradeLabel">
                Open Trade
              </h5>
              <button
                type="button"
                className=" ms-auto button-padding"
                data-bs-dismiss="modal"
                id="openTradeModelCloseBtn"
                aria-label="Close"
                onClick={handleRemoveOpenData}
              >
                ×
              </button>
            </div>
            <div class="modal-body">
              {/* <!-- trs det --> */}
              <Formik
                initialValues={tradeInput}
                enableReinitialize={true}
                validationSchema={
                  checkStop == "1" ? validationSchemaStopLoss : validationSchema
                }
                onSubmit={(value) => {
                  // alert(JSON.stringify(value))
                  handletradeBuySell(value);
                }}
              >
                {({ errors, touched, values }) => (
                  <Form>
                    <div className="row price-data-mr p-23-20 postion-min-dta border-0">
                      <div className="col-md-6 mb-2">
                        <label>
                          Select Portfolio{" "}
                          <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <Field
                          name="PortfolioID"
                          value={PortfolioID}
                          as="select"
                          onBlur={handleBlurOpenTrade}
                          onClick={() => setShowCodeList(false)}
                          className={"form-select input_border"}
                          onChange={handleChengeTradeInput}
                        >
                          <option value="" style={{ visibility: "hidden;" }}>
                            Select Portfolio
                          </option>
                          {brokerList?.map((val, ind) => {
                            return (
                              <option key={ind} value={val.portfolioId}>
                                {val.portfolioName}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.PortfolioID && touched.PortfolioID ? (
                          <div className="color-msg-err">
                            {errors.PortfolioID}
                          </div>
                        ) : null}
                        {/* <Select className='' onChange={setProviderSelect} options={optionsBroker} isClearable /> */}
                      </div>
                      <div className="col-md-6 mb-2">
                        <label>
                          Select Provider{" "}
                          <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <Field
                          name="ProviderID"
                          as="select"
                          onBlur={handleBlurOpenTrade}
                          onClick={() => setShowCodeList(false)}
                          className={"form-select input_border"}
                          onChange={handleChengeTradeInput}
                        >
                          <option value="" style={{ visibility: "hidden;" }}>
                            Select Provider
                          </option>
                          {providerList?.map((val, ind) => {
                            return (
                              <option key={ind} value={val.providerId}>
                                {val.providerName}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.ProviderID && touched.ProviderID ? (
                          <div className="color-msg-err">
                            {errors.ProviderID}
                          </div>
                        ) : null}
                        {/* <Select className='' onChange={setProviderSelect} options={optionsBroker} isClearable /> */}
                      </div>
                      <div className="col-md-6 mb-2">
                        <label>
                          Trade Type <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <Field
                          name="Share_Sector"
                          as="select"
                          value={Share_Sector}
                          onClick={() => setShowCodeList(false)}
                          onBlur={handleBlurOpenTrade}
                          className={
                            checkInput == true && Share_Sector == ""
                              ? "form-select input_border input-err-color"
                              : "form-select input_border"
                          }
                          onChange={handleChengeTradeInput}
                        >
                          {/* <option value="0" style={{ visibility: "hidden;" }}>Select Share</option> */}
                          <option value="SPOT">SPOT</option>
                          <option value="FUTURE">FUTURE</option>
                          <option value="PERPETUAL">PERPETUAL</option>
                          <option value="DEFI">DEFI</option>
                          <option value="TETHER">TETHER</option>
                          <option value="TETHERUS">TETHERUS</option>
                          <option value="INDEX">INDEX</option>
                          <option value="PREMIUM">PREMIUM</option>
                        </Field>
                      </div>

                      <div className="col-md-6">
                        <label>
                          Select Trading System{" "}
                          <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <Field
                          name="Strategy"
                          as="select"
                          value={Strategy}
                          onClick={() => setShowCodeList(false)}
                          onBlur={handleBlurOpenTrade}
                          className={"form-select input_border"}
                          onChange={handleChengeTradeInput}
                        >
                          <option value="" style={{ visibility: "hidden;" }}>
                            Select Trading System
                          </option>
                          {strategyList?.map((val, ind) => {
                            return (
                              <option key={ind} value={val.tradingSystemId}>
                                {val.name}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.Strategy && touched.Strategy ? (
                          <div className="color-msg-err">{errors.Strategy}</div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <label>
                          Select Crypto Type{" "}
                          <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <Field
                          name="StockName"
                          type="select"
                          onBlur={handleBlurOpenTrade}
                          value={StockName}
                          autoComplete="off"
                          placeholder="select Crypto"
                          className={"form-select input_border"}
                          list="brow"
                          onChange={handleChengeTradeInput}
                          onClick={() => {
                            setShowCodeList(!showCodeList);
                          }}
                        />
                        {/* { */}
                        {errors.StockCode && touched.StockCode ? (
                          <div className="color-msg-err">
                            {errors.StockCode}
                          </div>
                        ) : null}
                        <div
                          className="position-relative "
                          onBlur={() => setShowCodeList(false)}
                        >
                          <div
                            id="brow"
                            className="p-10px brow"
                            onScroll={handleGetSequrityName}
                            style={{
                              display:
                                showCodeList === true && ProviderID != ""
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {stockLists?.map((val, ind) => {
                              let stockArr = val.stockCode.split("_");
                              return (
                                <p
                                  className="d-flex justify-content-between option_stock"
                                  key={ind}
                                  value={val.stockCode}
                                  onClick={() => handleStockData(val)}
                                >
                                  <span>
                                    {stockArr[2]}/{stockArr[3]}
                                  </span>{" "}
                                  <span>
                                    {stockArr[0]}/{stockArr[1]}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                        </div>
                        {/* <Select options={stockLists} onChange={setCryptoSelect} isClearable /> */}
                      </div>
                      <div className="col-md-6">
                        <label>
                          Select type <span style={{ color: "#f00" }}>*</span>
                        </label>
                        <div className="d-flex justify-content-between">
                          <div class="form-check d-flex">
                            <input
                              class="form-check-input tradeType tradespace "
                              type="radio"
                              value=""
                              id="flexCheckCheckedDisabled"
                              checked={type == "TRADES"}
                              onClick={() =>
                                setTradeInput({ ...tradeInput, type: "TRADES" })
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckCheckedDisabled"
                            >
                              TRADES
                            </label>
                          </div>
                          <div class="form-check d-flex">
                            <input
                              class="form-check-input tradeType tradespace"
                              type="radio"
                              value=""
                              id="flexCheckCheckedDisabled"
                              checked={type == "TRENDS"}
                              onClick={() =>
                                setTradeInput({ ...tradeInput, type: "TRENDS" })
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckCheckedDisabled"
                            >
                              TRENDS
                            </label>
                          </div>
                          <div class="form-check d-flex">
                            <input
                              class="form-check-input tradeType tradespace"
                              type="radio"
                              value=""
                              id="flexCheckCheckedDisabled"
                              checked={type == "HODL"}
                              onClick={() =>
                                setTradeInput({ ...tradeInput, type: "HODL" })
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckCheckedDisabled"
                            >
                              HODL
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-2">
                        <label>Crypto Currency </label>
                        <Field
                          name="stockcurrency"
                          value={`${stockCountrySymbol ? stockCountrySymbol : ""} ${
                            stockcurrencyconvert
                              ? `${stockcurrencyconvert}`
                              : ""
                          }`}
                          className={"form-control"}
                        ></Field>
                      </div>
                      <div className="col-md-6 mb-2">
                        <label>Portfolio Currency</label>
                        <Field
                          name="portfoliocurrency"
                          value={`${portfolioCurrency}${
                            portfolioCountryCode
                              ? ` ${portfolioCountryCode}`
                              : ""
                          }`}
                          className={"form-control"}
                        ></Field>
                      </div>

                      <div className="col-md-6 mb-2">
                        <label>Exchange Rate </label>
                        <span className="symb">
                          {" "}
                          {stockcurrencyconvert && portfolioCountryCode
                            ? `${stockcurrencyconvert} / ${portfolioCountryCode}`
                            : ""}
                        </span>
                        {/* <Field name='exRate' value={exchangeRate ||  '0'} className={"form-select input_border"} >

                                                    </Field> */}
                        <div class="input-group mb-3">
                          <input
                            name="exchangerate"
                            type="text"
                            value={exchangeRate || "0"}
                            className={"form-control"}
                            placeholder=""
                          />
                          <span
                            class="input-group-text"
                            id="basic-addon1"
                            onClick={handleRefresh}
                          >
                            {" "}
                            {loading ? (
                              <div className="spinner"></div>
                            ) : (
                              <IoReload />
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 mb-2">
                        <label>Value In Crypto </label>
                        <Field
                          onClick={() => setShowCodeList(false)}
                          onBlur={handleBlurOpenTrade}
                          name="ValueInShares"
                          value={ValueInShares}
                          id="formGroupExampleInput"
                          placeholder="0"
                          autocomplete="off"
                          className={"form-control"}
                        />
                      </div>

                      <div class="col-lg-12 mt-3">
                        <div class=" about-content-bx top-know-price-bx top-know-price-bx-1">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="know-prd-bx">
                                <h6>ASK</h6>

                                <span
                                  class={
                                    checkAskPrLo == "up"
                                      ? "know-price-1 colorGreen"
                                      : checkAskPrLo == "down"
                                      ? "know-price-1 colorRed"
                                      : "know-price-1"
                                  }
                                >
                                  {" "}
                                  {stockCountrySymbol}{" "}
                                  {cryptoDetails[cryptoDetails?.length - 1]
                                    ?.askPrice || 0}{" "}
                                  {checkAskPrLo == "up" ? (
                                    <BsFillArrowUpRightCircleFill />
                                  ) : checkAskPrLo == "down" ? (
                                    <BsArrowDownRightCircleFill />
                                  ) : (
                                    ""
                                  )}
                                </span>
                                {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="know-prd-bx border-0">
                                <h6>BID</h6>

                                <span
                                  class={
                                    checkBidPrLo == "up"
                                      ? "know-price-1 colorGreen"
                                      : checkBidPrLo == "down"
                                      ? "know-price-1 colorRed"
                                      : "know-price-1"
                                  }
                                >
                                  {" "}
                                  {stockCountrySymbol}{" "}
                                  {cryptoDetails[cryptoDetails.length - 1]
                                    ?.bidPrice || 0}{" "}
                                  {checkBidPrLo == "up" ? (
                                    <BsFillArrowUpRightCircleFill />
                                  ) : checkBidPrLo == "down" ? (
                                    <BsArrowDownRightCircleFill />
                                  ) : (
                                    ""
                                  )}
                                </span>
                                {/* <span class="float-end know-price-2"><img src="assets/image/down-aero.svg" class="img-fluid"/> ₹6.538</span>   */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-lg-12">
                        <div class="postion-min-dta postion-frm focusCss mt-1 tread-vol-bx border-redious-top-left">
                          <div class="row">
                            <div class="col-8  border-bottom">
                              <ul
                                class="nav nav-pills "
                                id="pills-tab"
                                role="tablist"
                              >
                                <li class="nav-item" role="presentation">
                                  <button
                                    class={
                                      Short == "long" || Short == "buy"
                                        ? "nav-link active active-buy"
                                        : "nav-link"
                                    }
                                    // onClick={() =>
                                    //   handleChangeTradeType("long")
                                    // }
                                    onClick={() => handleChangeTradeType("buy")}
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#buyTradeMain"
                                    type="button"
                                    role="tab"
                                    aria-controls="buyTrade"
                                    aria-selected="true"
                                  >
                                    Buy
                                  </button>
                                </li>
                                <li class="nav-item " role="presentation">
                                  <button
                                    class={
                                      Short == "short" || Short == "sell"
                                        ? "nav-link active active-sell"
                                        : "nav-link"
                                    }
                                    // onClick={() =>
                                    //   handleChangeTradeType("short")
                                    // }
                                    onClick={() =>
                                      handleChangeTradeType("sell")
                                    }
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#sellTradeMain"
                                    type="button"
                                    role="tab"
                                    aria-controls="sellTrade"
                                    aria-selected="false"
                                  >
                                    Sell
                                  </button>
                                </li>
                              </ul>
                            </div>
                            <div class="col-4">
                              <select
                                type="text"
                                value={checkStop}
                                class="w-100 custom-select select-limit input_border"
                                onChange={handleChangeStopLoss}
                                id="formGroupExampleInput"
                                placeholder="0"
                                autocomplete="off"
                              >
                                <option value={"0"}>Limit</option>
                                <option value={"1"}>Stop Limit</option>
                                <option value={"2"}>Market</option>
                              </select>
                            </div>
                          </div>
                          <div class="tab-content" id="pills-tabContent">
                            <div
                              class={
                                Short == "long" || Short == "buy"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="buyTradeMain"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                            >
                              <div class="row">
                                <div class="">
                                  <div class=" mt-4">
                                    <div class="row">
                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={
                                            checkInput == true && TransFee == ""
                                              ? "know-commper-bx-min d-flex input-err-color "
                                              : "know-commper-bx-min d-flex "
                                          }
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              At Price {stockCountrySymbol}{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            name="TransFee"
                                            onBlur={handleBlurOpenTrade}
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            class="form-control p-0 w-75 height-39 "
                                            value={TransFee}
                                            onChange={handleChengeTradeInput}
                                            id="formGroufpExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.TransFee && touched.TransFee ? (
                                          <div className="color-msg-err">
                                            {errors.TransFee}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={"know-commper-bx-min d-flex"}
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              Value In Crypto{" "}
                                              {portfolioCurrency}{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control p-0 w-50 height-39"
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            onBlur={handleBlurOpenTrade}
                                            name="ValueInShares"
                                            value={ValueInShares}
                                            onChange={handleChengeTradeInput}
                                            id="formGroupExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.ValueInShares &&
                                        touched.ValueInShares ? (
                                          <div className="color-msg-err">
                                            {errors.ValueInShares}
                                          </div>
                                        ) : null}
                                        {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                      </div>
                                    </div>
                                    <div class="row ">
                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={"know-commper-bx-min d-flex"}
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              Quantity{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control p-0 w-75 height-39 "
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            onBlur={handleBlurOpenTrade}
                                            name="quantity"
                                            value={quantity}
                                            onChange={handleChengeTradeInput}
                                            id="formGroupExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.quantity && touched.quantity ? (
                                          <div className="color-msg-err">
                                            {errors.quantity}
                                          </div>
                                        ) : null}
                                      </div>
                                      {checkStop == "1" && (
                                        <div class="col-lg-6 mb-4">
                                          <div
                                            class={"know-commper-bx-min d-flex"}
                                          >
                                            <div>
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label label_grey_input"
                                              >
                                                Stop Price {stockCountrySymbol}{" "}
                                                <span style={{ color: "#f00" }}>
                                                  *
                                                </span>
                                              </label>
                                              <span className="border-2px"></span>
                                            </div>
                                            <input
                                              type="text"
                                              class="form-control p-0 w-75 height-39"
                                              onClick={() =>
                                                setShowCodeList(false)
                                              }
                                              onBlur={validationStoploss}
                                              name="StopLossPrice"
                                              value={StopLossPrice}
                                              onChange={handleChengeTradeInput}
                                              id="formGroupExampleInput"
                                              placeholder="0"
                                              autocomplete="off"
                                            />
                                          </div>
                                          {errors.StopLossPrice &&
                                          touched.StopLossPrice ? (
                                            <div className="color-msg-err">
                                              {errors.StopLossPrice}
                                            </div>
                                          ) : null}

                                          {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* <div class="compare-bx mt-4 mb-4">
                                                                                <span class="comere-icon-bx">
                                                                                    <i class="bi bi-arrow-down-up"></i>
                                                                                </span>
                                                                            </div> */}

                                  <button
                                    class="btn col-lg-12 buyButton"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    Trade Buy
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div
                              class={
                                Short == "short" || Short == "sell"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="sellTradeMain"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                            >
                              <div class="row">
                                <div class="">
                                  <div class=" mt-4">
                                    <div class="row">
                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={
                                            checkInput == true && TransFee == ""
                                              ? "know-commper-bx-min d-flex input-err-color "
                                              : "know-commper-bx-min d-flex "
                                          }
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              At Price {stockCountrySymbol}{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            name="TransFee"
                                            onBlur={handleBlurOpenTrade}
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            class="form-control p-0 w-75 height-39 "
                                            value={TransFee}
                                            onChange={handleChengeTradeInput}
                                            id="formGroupExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.TransFee && touched.TransFee ? (
                                          <div className="color-msg-err">
                                            {errors.TransFee}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={"know-commper-bx-min d-flex"}
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              Value In Crypto{" "}
                                              {portfolioCurrency}{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control p-0 w-50 height-39"
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            onBlur={handleBlurOpenTrade}
                                            name="ValueInShares"
                                            value={ValueInShares}
                                            onChange={handleChengeTradeInput}
                                            id="formGroupExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.ValueInShares &&
                                        touched.ValueInShares ? (
                                          <div className="color-msg-err">
                                            {errors.ValueInShares}
                                          </div>
                                        ) : null}
                                        {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-lg-6 mb-4">
                                        <div
                                          class={"know-commper-bx-min d-flex"}
                                        >
                                          <div>
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label label_grey_input"
                                            >
                                              Quantity{" "}
                                              <span style={{ color: "#f00" }}>
                                                *
                                              </span>
                                            </label>
                                            <span className="border-2px"></span>
                                          </div>
                                          <input
                                            type="text"
                                            class="form-control w-75 p-0 height-39"
                                            onClick={() =>
                                              setShowCodeList(false)
                                            }
                                            name="quantity"
                                            onBlur={handleBlurOpenTrade}
                                            value={quantity}
                                            onChange={handleChengeTradeInput}
                                            id="formGroupExampleInput"
                                            placeholder="0"
                                            autocomplete="off"
                                          />
                                        </div>
                                        {errors.quantity && touched.quantity ? (
                                          <div className="color-msg-err">
                                            {errors.quantity}
                                          </div>
                                        ) : null}
                                      </div>

                                      {checkStop == "1" && (
                                        <div class="col-lg-6 mb-4">
                                          <div
                                            class={"know-commper-bx-min d-flex"}
                                          >
                                            <div>
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label label_grey_input"
                                              >
                                                Stop Price {stockCountrySymbol}{" "}
                                                <span style={{ color: "#f00" }}>
                                                  *
                                                </span>
                                              </label>
                                              <span className="border-2px"></span>
                                            </div>
                                            <input
                                              type="text"
                                              class="form-control w-75 p-0 height-39"
                                              onClick={() =>
                                                setShowCodeList(false)
                                              }
                                              onBlur={validationStoploss}
                                              name="StopLossPrice"
                                              value={StopLossPrice}
                                              onChange={handleChengeTradeInput}
                                              id="formGroupExampleInput"
                                              placeholder="0"
                                              autocomplete="off"
                                            />
                                          </div>

                                          {errors.StopLossPrice &&
                                          touched.StopLossPrice ? (
                                            <div className="color-msg-err">
                                              {errors.StopLossPrice}
                                            </div>
                                          ) : null}
                                          {/* <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  {/* <div class="compare-bx mt-4 mb-4">
                                                                                <span class="comere-icon-bx">
                                                                                    <i class="bi bi-arrow-down-up"></i>
                                                                                </span>
                                                                            </div> */}

                                  <button
                                    class="btn col-lg-12 sellButton"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    Trade Sell
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* 
                                                            <div class=" about-content-bx top-know-price-bx">
                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Quantity</label>
                                                                                <span className='border-2'></span>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="0" autocomplete="off" />
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="compare-bx mt-4 mb-4">
                                                                    <span class="comere-icon-bx">
                                                                        <i class="bi bi-arrow-down-up"></i>
                                                                    </span>
                                                                </div>

                                                                <div class="know-commper-bx mt-5 mb-5">
                                                                    <div class="row">
                                                                        <div class="col-lg-8">
                                                                            <div class="know-commper-bx-min d-flex">
                                                                                <label for="formGroupExampleInput" class="form-label">Price</label>
                                                                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder="6.544" />
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-4 pt-3">
                                                                            <span><img src="assets/image/Bitmapindia-e1f7f5a05e7274c448d305e6d8482321.svg" class="img-fluid" /> INR </span>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <button class="btn col-lg-12">Trade Now</button>


                                                            </div>
                                                            */}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <!-- trs det --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenTrade;
